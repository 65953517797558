.clrText {
  color: #007bff !important;
}
.root {
  flex-grow: 1;
  color: #484848;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 65px);
}
.title {
  flex-grow: 1;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  max-width: 100% !important;
}
.h-link {
  position: absolute;
  left: 20px;
  top: 75px;
}
.bg-image {
  min-height: 400px;
  width: 100%;
  background-image: linear-gradient(
    0deg,
    rgba(25, 118, 210, 0.4),
    rgba(0, 0, 0, 0.25)
  );
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.bg-image h1 {
  color: white;
  font-size: 2.5rem;
}
.searchBox {
  margin: 32px auto;
  width: 90%;
  background-color: white;
  border-radius: 2px;
  max-width: 500px;
}

.searchIn {
  display: flex;
  min-width: 80%;
  justify-content: center;
  margin-bottom: 15px;
  max-width: 500px;
}

.searchButton {
  height: 54px;
}
#table {
  min-width: 650px;
}

footer {
  min-height: 50px;
  margin-top: 25px;
}

.multiline {
  /* white-space: pre-wrap;
  word-break: break-all; */
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 600px;
  overflow: hidden;
}
.select {
  position: absolute !important;
  right: 142px;
  margin: 0 !important;
}
.url {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 230px;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .searchBox {
    width: 95%;
  }

  .searchIn {
    width: 95%;
  }
}
