.caption-arch-title {
  color: rgb(131, 146, 171);
  line-height: 1.25;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
}

.caption-arch-value {
  color: rgb(131, 146, 171);
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.25;
  letter-spacing: 0.03333em;
}
