.today {
  width: 300px;
  background-color: #673ab7;
  color: white;
  margin-bottom: 20px;
  padding: 5px;
}

.week {
  width: 300px;
  background-color: #3f51b5;
  color: white;
  margin-bottom: 20px;
  padding: 5px;
}

.month {
  width: 300px;
  background-color: #2196f3;
  color: white;
  margin-bottom: 20px;
  padding: 5px;
}

.size {
  width: 300px;
  background-color: #009688;
  color: white;
  padding: 5px;
}
.b1 {
  background-color: #a29416;
  margin: 0;
}
