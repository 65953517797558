.clrText {
  color: #007bff !important;
}

.archResult {
  padding-bottom: 10px;
  font-size: 0.75rem;
}

.logo {
  position: absolute;
  top: 0px;
  left: 20px;
}

.blstTxtContainer {
  border: 1px solid rgba(224, 224, 224, 1);
  border-bottom: none;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 2px;
  position: relative;
}
